<template>
  <div>
    <slot :openDialog="openDialog"></slot>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title primary-title>
          <h4 class="headline logo_text">
            <i class="fa fa-map-o logo_icon" />&nbsp;Publish {{ currMap.title }}
          </h4>
        </v-card-title>

        <v-divider />

        <v-card-text>
          <p />
          Are you sure you want to publish this map?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="publishMap">
            Publish
          </v-btn>
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Publish",
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapGetters(["currMap"])
  },
  methods: {
    async publishMap() {
      await this.$store.dispatch("publishMap", this.currMap.title);
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    }
  }
};
</script>
